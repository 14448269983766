var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('Page-Header',{attrs:{"icon":'mdi-shield-moon-outline',"title":_vm.$t('nightshifts.nightshifts')},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.isInRole(_vm.roles.nightShift.create))?_c('v-btn',{attrs:{"color":"primary darken-1"},nativeOn:{"click":function($event){return _vm.addNewNightshift($event)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1):_vm._e()]},proxy:true}])}),_c('v-data-table',{staticClass:"pa-5",staticStyle:{"overflow":"hidden"},attrs:{"headers":_vm.headers,"items":_vm.nightshifts,"search":_vm.search,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isInRole(_vm.roles.nightShift.delete))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])])]}},{key:"item.nightshiftDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.nightshiftDate,"yyyy-MM-DD"))+" ")]}}],null,true)}),_c('Nightshift-Dialog',{key:_vm.dialog,attrs:{"loading":_vm.loading,"dialog":_vm.dialog,"editedItem":_vm.editedItem,"editedIndex":_vm.editedIndex,"closeDialog":_vm.closeDialog,"employees":_vm.employees,"refreshData":_vm.getNightshifts,"selectedNightshiftDates":_vm.selectedNightshiftDates}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteNightshift,"onClose":_vm.closeDeleteDialog,"toolBarColor":"red darken-2"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }