<template>
    <v-container fluid>
        <v-card>
            <Page-Header
                :icon="'mdi-shield-moon-outline'"
                :title="$t('nightshifts.nightshifts')"
            >
                <template v-slot:buttons>
                    <v-btn
                        v-if="isInRole(roles.nightShift.create)"
                        color="primary darken-1"
                        @click.native="addNewNightshift"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                :headers="headers"
                :items="nightshifts"
                :search="search"
                class="pa-5"
                style="overflow: hidden;"
                :loading-text="$t('loading')"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-if="isInRole(roles.nightShift.delete)"
                                @click="openDeleteDialog(item)"
                                v-bind="attrs"
                                v-on="on"
                                color="red"
                            >
                                mdi-delete-outline
                            </v-icon>
                        </template>
                        <span> {{ $t("delete") }}</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.nightshiftDate`]="{ item }">
                    {{ item.nightshiftDate | moment("yyyy-MM-DD") }}
                </template>
            </v-data-table>
            <Nightshift-Dialog
                :loading="loading"
                :dialog="dialog"
                :editedItem="editedItem"
                :editedIndex="editedIndex"
                :closeDialog="closeDialog"
                :employees="employees"
                :refreshData="getNightshifts"
                :selectedNightshiftDates="selectedNightshiftDates"
                :key="dialog"
            />
            <!-- Delete popup -->
            <confirm-dialog
                :openDialog="dialogDelete"
                :onClicked="deleteNightshift"
                :onClose="closeDeleteDialog"
                toolBarColor="red darken-2"
            ></confirm-dialog>
        </v-card>
    </v-container>
</template>
<script>
import PageHeader from "../../components/PageHeader.vue";
import NightshiftsApi from "../../Api/NightshiftsApi";
import NightshiftDialog from "./NightshiftDialog.vue";
import EmployeesApi from "../../Api/EmployeesApi";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import moment from "moment";

export default {
    created() {
        if (!this.isInRole(this.roles.nightShift.window))
            this.redirectUnauthorizedUsers();
        this.getEmployees();
        this.getNightshifts();
    },
    data() {
        return {
            loading: false,
            headers: [
                {
                    text: this.$t("nightshifts.supervisorEmployee"),
                    value: "supervisorEmployeeName"
                },
                {
                    text: this.$t("nightshifts.adminEmployee"),
                    value: "administrativeEmployeeName"
                },
                {
                    text: this.$t("nightshifts.nightshiftDate"),
                    value: "nightshiftDate"
                },
                { text: "", value: "actions" }
            ],
            search: null,
            editedIndex: -1,
            editedItem: {},
            defaultNightshift: {
                nightshiftGuid: null,
                nightshiftDate: null,
                employeeGuid: null
            },
            nightshift: null,
            nightshifts: [],
            employees: [],
            dialog: false,
            dialogDelete: false
        };
    },
    components: {
        PageHeader,
        NightshiftDialog,
        ConfirmDialog
    },
    methods: {
        getNightshifts() {
            NightshiftsApi.getNightshifts()
                .then(response => {
                    this.nightshifts = response.data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getEmployees() {
            EmployeesApi.GetNightshiftEmployees()
                .then(response => {
                    this.employees = response.data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        closeDialog() {
            this.dialog = false;
        },
        addNewNightshift() {
            this.editedItem = Object.assign({}, this.defaultNightshift);
            this.editedIndex = -1;
            this.dialog = true;
        },
        openDeleteDialog(nightshift) {
            this.nightshift = Object.assign({}, nightshift);
            this.dialogDelete = true;
        },
        closeDeleteDialog() {
            this.dialogDelete = false;
        },
        deleteNightshift() {
            try {
                NightshiftsApi.deleteNightshift(this.nightshift.nightshiftGuid)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok)
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        else
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                    })
                    .finally(() => {
                        this.closeDeleteDialog();
                        this.getNightshifts();
                    });
            } catch (error) {
                console.log(error);
            }
        }
    },
    computed: {
        selectedNightshiftDates() {
            if (
                this.nightshifts != null &&
                this.nightshifts != [] &&
                this.nightshifts.length != 0
            )
                return this.nightshifts.map(w =>
                    moment(w.nightshiftDate).format("YYYY-MM-DD")
                );
            else return [];
        }
    }
};
</script>
