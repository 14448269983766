var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":"primary","elevation":"0","dark":""}},[_vm._v(" "+_vm._s(_vm.editedIndex == -1 ? _vm.$t("add") : _vm.$t("edit"))+" "+_vm._s(_vm.$t("nightshifts.nightshift"))+" ")])],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.employees,"outlined":"","hide-details":"","rules":_vm.rules,"item-value":"employeeGuid","item-text":"employeeName","dense":"","label":_vm.$t('nightshifts.supervisorEmployee')},model:{value:(_vm.editedItem.SupervisorEmployeeGuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "SupervisorEmployeeGuid", $$v)},expression:"editedItem.SupervisorEmployeeGuid"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.employees,"outlined":"","hide-details":"","rules":_vm.rules,"item-value":"employeeGuid","item-text":"employeeName","dense":"","label":_vm.$t('nightshifts.adminEmployee')},model:{value:(
                                    _vm.editedItem.AdministrativeEmployeeGuid
                                ),callback:function ($$v) {_vm.$set(_vm.editedItem, "AdministrativeEmployeeGuid", $$v)},expression:"\n                                    editedItem.AdministrativeEmployeeGuid\n                                "}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","hide-details":"","rules":_vm.rules,"dense":"","label":_vm.$t('nightshifts.nightshiftDate'),"readonly":""},model:{value:(_vm.editedItem.nightshiftDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "nightshiftDate", $$v)},expression:"editedItem.nightshiftDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"min":new Date(
                                            Date.now() -
                                                new Date().getTimezoneOffset() *
                                                    60000
                                        )
                                            .toISOString()
                                            .substr(0, 10)},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.editedItem.nightshiftDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "nightshiftDate", $$v)},expression:"editedItem.nightshiftDate"}})],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save")))],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("cancel")))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }