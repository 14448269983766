import axios from "axios";

export default class NightshiftsApi {
    static getNightshifts(id = null) {
        if (id != null)
            return axios
                .get("Nightshifts/Get?id=" + id)
                .then(response => response);
        return axios.get("Nightshifts/Get").then(response => response);
    }

    static CreateNightshift = nightshift => {
        return axios
            .post("Nightshifts/Create", nightshift)
            .then(response => response);
    };

    static deleteNightshift = nightshiftGuid => {
        return axios
            .delete("Nightshifts/Delete?NightshiftGuid=" + nightshiftGuid)
            .then(response => response);
    };
}
