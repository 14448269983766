<template>
    <v-dialog persistent v-model="dialog" max-width="800px">
        <v-card class="ma-0 pa-0">
            <v-card-title class="pa-0">
                <v-toolbar color="primary" elevation="0" dark>
                    {{ editedIndex == -1 ? $t("add") : $t("edit") }}

                    {{ $t("nightshifts.nightshift") }}
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="editedItem.SupervisorEmployeeGuid"
                                    :items="employees"
                                    outlined
                                    hide-details
                                    :rules="rules"
                                    item-value="employeeGuid"
                                    item-text="employeeName"
                                    dense
                                    :label="
                                        $t('nightshifts.supervisorEmployee')
                                    "
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-autocomplete
                                    v-model="
                                        editedItem.AdministrativeEmployeeGuid
                                    "
                                    :items="employees"
                                    outlined
                                    hide-details
                                    :rules="rules"
                                    item-value="employeeGuid"
                                    item-text="employeeName"
                                    dense
                                    :label="$t('nightshifts.adminEmployee')"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            outlined
                                            v-model="editedItem.nightshiftDate"
                                            hide-details
                                            :rules="rules"
                                            dense
                                            :label="
                                                $t('nightshifts.nightshiftDate')
                                            "
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="editedItem.nightshiftDate"
                                        @input="menu = false"
                                        :allowed-dates="allowedDates"
                                        :min="
                                            new Date(
                                                Date.now() -
                                                    new Date().getTimezoneOffset() *
                                                        60000
                                            )
                                                .toISOString()
                                                .substr(0, 10)
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-row no-gutters>
                    <v-col cols="auto"
                        ><v-btn @click="save" color="primary">
                            <v-icon>mdi-content-save-outline</v-icon>
                            {{ $t("save") }}</v-btn
                        ></v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col cols="auto"
                        ><v-btn @click="closeDialog" color="red" text>
                            {{ $t("cancel") }}</v-btn
                        ></v-col
                    >
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import moment from "moment";
import NightshiftsApi from "../../Api/NightshiftsApi";

export default {
    props: [
        "dialog",
        "editedItem",
        "closeDialog",
        "editedIndex",
        "employees",
        "refreshData",
        "selectedNightshiftDates"
    ],
    data() {
        return {
            nightshifts: [],
            headers: [
                {
                    text: this.$t("employees.employeeName"),
                    value: "employeeName"
                },
                {
                    text: this.$t("nightshifts.nightshiftDate"),
                    value: "nightshiftDate"
                },
                { text: "", value: "actions" }
            ],
            menu: false,
            rules: [value => !!value || this.$t("required")]
        };
    },
    methods: {
        clearNightshiftForm() {
            this.editedItem.nightshiftDate = moment(moment()).format(
                "YYYY-MM-DD"
            );
            this.editedItem.employeeGuid = null;
        },
        save() {
            try {
                if (!this.$refs.form.validate()) return;

                NightshiftsApi.CreateNightshift(this.editedItem)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.refreshData();
                            this.closeDialog();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    });
            } catch (error) {
                console.log(error);
            }
        },
        allowedDates(a) {
            return !this.selectedNightshiftDates.includes(a);
        }
    }
};
</script>
