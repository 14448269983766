import axios from "axios";

export default class EmployeesApi {
    static getEmployeesMinimalData() {
        return axios
            .get("Employees/GetEmployeesMinimalData")
            .then(response => response);
    }

    static GetEmployeesInfo(itemsPerPage, pageNumber, keyword, departmentsGuid = null) {
        return axios
            .get(departmentsGuid ? ("Employees/GetEmployeesInfo?" +  departmentsGuid.map(d=>{return "departmentsGuid="+d+"&"}).join('')) : "Employees/GetEmployeesInfo" , {
                params: {
                    size: itemsPerPage,
                    page: pageNumber,
                    keyword: keyword
                }
            })
            .then(response => response);
    }

    static Search(employeeName) {
        return axios
            .get("Employees/Search?employeeName=" + employeeName)
            .then(response => response);
    }

    static GetAlertDepartmentsEmployees(search,skip, take) {
        return axios
            .get("Employees/GetAlertDepartmentsEmployees?search=" + search + "&skip=" + skip + "&take=" + take)
            .then(response => response);
    }

    static GetHighPositionEmployees() {
        return axios
            .get("Employees/GetHighPositionEmployees")
            .then(response => response);
    }

    static GetByPositionAndDepartments(positionTypes, departments) {
        return axios
            .post("Employees/GetByPositionAndDepartments", {
                positionTypes: positionTypes,
                departments: departments
            })
            .then(response => response);
    }

    static GetNightshiftEmployees() {
        return axios
            .get("Employees/GetNightshiftEmployees")
            .then(response => response);
    }

    static changeEmployeePassword(employeeGuid, newPassword, confirmPassword) {
        return axios
            .post("Employees/ChangeEmployeePassword", {
                employeeGuid: employeeGuid,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            })
            .then(response => response);
    }
}
